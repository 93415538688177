<template>
  <v-container class="layout-container">
    <HelpCenter :company="company" />
  </v-container>
</template>

<script>
import COMPANY_BASE from '../queries/CompanyBase.gql'

export default {
  components: {
    HelpCenter: () => import('@/modules/helpCenter')
  },
  apollo: {
    company: {
      query: COMPANY_BASE,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      }
    }
  }
}
</script>
